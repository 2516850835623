<template>
  <div>
    <div class="clicked" style="color: white;">{{clickedOn}}</div>
    <button class="button" @click="addData()">Add random node</button>
    <button class="button" @click="removeData()" style="left: 200px;">Remove random node</button>
    <div class="buttons" style="color: white;">
      <div v-for="type in this.nodeTypes" :key="'select-'+type">
        <input type = "checkbox" :id="type" :value="type" v-model="typePicker" @click="typeChange">
        <label :for="type"> {{type}}</label>
      </div>
    </div>
    <div id="arc" />
  </div>
</template>

<script>
const axios = require("axios");
const d3 = require("d3");
const config = require("../../assets/config");

export default {
  name: "D3NetworkExperiments",
  beforeCreate: function() {
    document.body.className = 'd3';
  },
  data() {
   return {
     width: '100vw',
     height: '100vh',
     clickedOn: '',
     nodes: null,
     links: null,
     values: config,
     colorLookup: {
       'tvShow': '#9999dd',
       'person': '#cc4444',
       'character': '#44cc44',
       'role': '#bbbb88'
     },
     textColorLookup: {
       'tvShow': '#9999ff',
       'person': '#cc3333',
       'character': '#33cc33',
       'role': '#bbbb33'
     },
     rawNodes: null,
     rawLinks: null,
     nodeTypes: [],
     typePicker: []
   };
  },
  mounted() {
    this.simulation = d3.forceSimulation()
        .force("link", d3.forceLink().distance(document.body.clientWidth/12).id(d => d.id))
        .force("charge", d3.forceManyBody())
        .force("center", d3.forceCenter(document.body.clientWidth/2, window.innerHeight/2))
        .alphaTarget(0.5);
    this.svg = d3.select("#arc")
        .append("svg")
        .style("width", document.body.clientWidth + 'px')
        .style("height", window.innerHeight + 'px');
    this.startup();
    window.addEventListener('resize', this.onResize);
  },
  beforeDestroy() { 
    window.removeEventListener('resize', this.onResize); 
  },
  methods: {
    onResize() {
      d3.select('svg')
        .style("width", document.body.clientWidth + 'px')
        .style("height", window.innerHeight + 'px');
      this.simulation
        .force("link", d3.forceLink().distance(document.body.clientWidth/12).id(d => d.id))
        .force("center", d3.forceCenter(document.body.clientWidth/2, window.innerHeight/2));
      this.generateArc();
    },
    startup() {
      var config = {
        headers: {
          "Content-Type": "application/json"
        }
      };
      var body = {  };
      var baseUrl = this.values.BACKEND_CONNECTION + "://" + this.values.BACKEND_SERVER + ":" + this.values.BACKEND_SERVER_PORT + "/api/mongo/network/";
      axios
      .get(baseUrl+'nodes',body,config)
      .then(response => {
          this.rawNodes = response['data'];
          axios
            .get(baseUrl+'links',body,config)
            .then(response => {
              this.rawLinks = response['data'];
              for (let n of this.rawNodes) {
                if (!this.nodeTypes.includes(n['type'])) {
                  this.nodeTypes.push(n['type'])
                }
              }
              this.typePicker = [...this.nodeTypes]
              this.nodes = [...this.rawNodes]
              this.links = [...this.rawLinks]
              this.generateArc();
            })
      })
      .catch(error => {
          this.errorValue = error;
      })
    },
    generateArc() {
      this.svg.selectAll('#linkset').remove();
      this.svg.selectAll('#translinkset').remove();
      this.svg.selectAll('#nodeset').remove();


      this.link = this.svg.append("g")
        .attr('id','linkset')
        .selectAll("g")
        .data(this.links)
        .enter().append("line")
        .attr("stroke", "#252")
        .attr("stroke-opacity", 0.8)
        .attr("stroke-width", 1);

      this.linkLabel = this.svg.selectAll('#linkset')
        .selectAll("text")
        .data(this.links)
        .enter().append("text")
        .attr("stroke", "none")
        .attr("font-family", "monospace")
        .attr("font-size", "8px")
        .style('fill', '#363')
        .text(function(d) { return d.type });

      this.transLink = this.svg.append("g")
        .attr('id','translinkset')
        .selectAll("g")
        .remove()
        .data(this.links)
        .enter().append("line")
        .attr("stroke", "transparent")
        .attr("stroke-width", 10)
        .style("cursor", "pointer")
        .on("click", this.doClickLink);

      this.node = this.svg.append("g")
        .attr('id','nodeset')
        .selectAll("g")
        .data(this.nodes)
        .attr('id', d => 'node_'+d.id)
        .attr('class','nodes')
        .attr('x', document.body.clientWidth/2)
        .attr('y', window.innerHeight/2)
        .style("cursor", "pointer")
        .enter().append("g")
        .call(this.drag(this.simulation))
        .on("click", this.doClickNode);

      this.node.append("circle")
        .attr('id', d => 'circle_'+d.id.replace(new RegExp('[\"\.\']', 'g'),'').replace(new RegExp(' ', 'g'),'_'))
        .attr('class','nodeitem')
        .attr("r", 5)
        .attr("fill", d => this.colorLookup[d.type])
        .attr("x", -8)
        .attr("y", -8)
        .attr("width", 16)
        .attr("height", 16)
        .style("cursor", "pointer");

      this.node.append("text")
        .attr("stroke", "none")
        .attr("font-family", "monospace")
        .attr("font-size", "12px")
        .attr('fill', d => this.textColorLookup[d.type])
        .style("text-shadow", "0 0 10px #6a6, 0 0 20px #6a6, 0 0 30px #171")
        .attr("dx", 12)
        .attr("dy", ".35em")
        .style("cursor", "pointer")
        .text(function(d) { return d.id });

      this.simulation.nodes(this.nodes);
      this.simulation.force("link").links(this.links);
      this.simulation.alphaTarget(0.99).restart();

      this.simulation.on("tick", () => {
        this.link
            .attr("x1", d => d.source.x)
            .attr("y1", d => d.source.y)
            .attr("x2", d => d.target.x)
            .attr("y2", d => d.target.y);
        this.linkLabel
            .attr('x', d => (d.source.x+d.target.x)/2)
            .attr('y', d => (d.source.y+d.target.y)/2)
        this.transLink
            .attr("x1", d => d.source.x)
            .attr("y1", d => d.source.y)
            .attr("x2", d => d.target.x)
            .attr("y2", d => d.target.y);
        this.node
            .attr("transform", function(d) { return "translate(" + d.x + "," + d.y + ")"; });
      });
    },
    drag(simulation) {
      function dragstarted(event) {
        if (!event.active) simulation.alphaTarget(0.8).restart();
        event.subject.fx = event.subject.x;
        event.subject.fy = event.subject.y;
      }
      
      function dragged(event) {
        event.subject.fx = event.x;
        event.subject.fy = event.y;
      }
      
      function dragended(event) {
        if (!event.active) simulation.alphaTarget(0.5);
        event.subject.fx = null;
        event.subject.fy = null;
      }

      return d3.drag()
          .on("start", dragstarted)
          .on("drag", dragged)
          .on("end", dragended);
    },
    doUnselect(string) {
      let prev = this.svg.select('#circle_'+string.replace(new RegExp('[\"\.\']', 'g'),'').replace(new RegExp(' ', 'g'),'_'));
      prev.attr('stroke','none');
      prev.attr('stroke-width',0);
    },
    doClickNode(d,i) {
      if (this.clickedOn) {
        this.doUnselect(this.clickedOn);
      }
      let f = this.svg.select('#circle_'+i.id.replace(new RegExp('[\"\.\']', 'g'),'').replace(new RegExp(' ', 'g'),'_'));
      let color = (f.attr('fill'))
      let flick = false;
      f.attr('stroke','white');
      f.attr('stroke-width',2);
      this.clickedOn = i.id;
      for (i = 0; i < 6; i++) {
        setTimeout(() => {
          if (!flick) {
            flick = true;
            f.attr('fill','#000600');
          }
          else {
            flick = false;
            f.attr('fill',color);
          }
        },i*150)
      }
    },
    doClickLink(d,i) {
      if (this.clickedOn) {
        this.doUnselect(this.clickedOn);
      }
      this.clickedOn = i.source.id + ' to ' + i.target.id;
    },
    addData() {
      let tempString = Math.random().toString(36).replace(/[^a-z]+/g, '').substr(0, 5);
      this.nodes.push({id: tempString, type: 'character'})
      let links = Math.floor(Math.random()*3) + 1;
      let linkers = []
      for (let i = 0; i < links; i ++) {
        linkers.push(this.nodes[Math.floor(Math.random()*this.nodes.length)].id)
      }
      for (let i = 0; i < linkers.length; i++) {
        this.links.push({source: tempString, target: linkers[i], type: 'connection'})
      }
      this.generateArc();
    },
    removeData() {
      let remItem = this.nodes[Math.floor(Math.random()*this.nodes.length)];
      for( var i = 0; i < this.nodes.length; i++){ 
        if ( this.nodes[i] === remItem) {
          this.nodes.splice(i, 1); 
        }
      }
      this.links = this.links.filter(function(value){ 
        return (value['source']['id'] != remItem['id'] && value['target']['id'] != remItem['id']);
      });
      this.generateArc();
    },
    typeChange() {
      let newNodes = [];
      let newLinks = [];
      setTimeout(() => {
        for (let n of this.typePicker) {
          for (let i of this.rawNodes) {
            if (i['type'] == n) {
              newNodes.push(i);
            }
          }
        }
        let nodeList = []
        for (let n of newNodes) {
          nodeList.push(n['id']);
        }
        for (let i of this.rawLinks) {
          if (!newLinks.includes(i) && nodeList.includes(i['source']['id']) && nodeList.includes(i['target']['id'])) {
            newLinks.push(i);
          }
        }
        this.nodes = [...newNodes];
        this.links = [...newLinks];
        this.generateArc();
      }, 0);
    }
  },
  computed: {}
};
</script>


<style>
text {
  user-select: none;
}
</style>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

div.clicked {
  position: absolute;
  top: 10px;
  left: 10px;
}

div.buttons {
  position: absolute;
  top: 70px;
  left: 10px;
}

button {
  position: absolute;
  top: 40px;
  left: 10px;
}

</style>
